import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ENDPOINT } from '../../../constants/endpoints';
import { ApiService } from '../../../data/api.service';
import { DelegationService } from '../../../shared/delegation.service';
import { EmployerService } from '../../../shared/employer.service';

@Component({
  selector: 'app-list-employers',
  templateUrl: './list-employers.component.html',
  styleUrls: ['../../../../app/views/app/app.component.scss'],
})
export class ListEmployersComponent implements OnInit {
  public employers: any = [];
  public countEmployers: number;
  constructor(
    private apiService: ApiService,
    private notifications: NotificationsService,
    private router: Router,
    private delegationService: DelegationService,
    private employerService: EmployerService,
  ) {}

  ngOnInit(): void {
    const endpoint = ENDPOINT.listeempaut.path;

    this.apiService.get('mdne', endpoint).subscribe({
      next: (response) => {
        this.countEmployers=response.Employeurs_autorises.length 
        //non autorisés
        if (response && this.isAllNon(response.Employeurs_autorises)) {
          this.delegationService.isResponsible = false;
          this.router.navigate(['employeur/liste-employeurs']);
        } else {
          this.delegationService.isResponsible = true;
        }
        //autorisés
        if (response && response.Employeurs_autorises.length > 1) {
          localStorage.setItem('disableListEmp', 'false');
          this.employers = response.Employeurs_autorises;
        }

        if (response &&  response.Employeurs_autorises.length=== 1) {
          this.employers = response.Employeurs_autorises;
          this.delegationService.numEmployer = parseInt(
            response.Employeurs_autorises[0].Num_employeur,
          );
          this.router.navigate(['/employeur/app/informations']);
          localStorage.setItem('disableListEmp','true')
          this.delegationService.currentSociety = response.Employeurs_autorises[0].Nom_employeur;
          
        }else{
          localStorage.setItem('disableListEmp','false')
        }
        localStorage.setItem('countEmployersAllowed',response.Employeurs_autorises.length)
      },
      error: (error) => {
        this.onError('erreur', "Impossible d'afficher la liste des employeurs autorisés");
        console.error(error);
      },
    });
  }

  public selectEmployer(id: string, nameSociety: string, isAllowed?: string) {
    if (this.delegationService.isResponsible === 'true') {
        this.delegationService.numEmployer = parseInt(id);
        this.router.navigate(['/employeur/app/informations']);
    } else {
      this.router.navigate(['/employeur/app/informations']);
    }
    this.delegationService.numEmployer = parseInt(id);
    this.delegationService.currentSociety = nameSociety;
    isAllowed === 'NON' ? localStorage.setItem('isResponsible', 'false') : localStorage.setItem('isResponsible', 'true'); 
  }

  public onError(error: string, content: string): void {
    this.notifications.create(error, content, NotificationType.Error, {
      timeOut: 3000,
      showProgressBar: true,
    });
  }

  public isAllNon(data: any): boolean {
    return data.every((employeur) => employeur.Responsable_delegation === 'NON');
  }
}
