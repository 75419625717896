import { Injectable } from '@angular/core';
import { ApiService } from '../data/api.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class EmployerService {
  constructor(private apiService: ApiService) {}

  public getCountEmployersAllowed(): Observable<number> {
    return this.apiService
      .get('mdne', 'listeempaut')
      .pipe(map((response) => response.Employeurs_autorises.length));
  }
}
